<template>
  <div class="mx-4 mt-4">
    <div class="card col-12">
      <div class="mt-4">
        <iframe
          :src="url"
          style="width: 100%; min-height: calc(100vh - 170px)"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      url: process.env.VUE_APP_REPORTS_CASH_REGISTER_READINGS_URL
    };
  },
};
</script>
<style></style>
